/* 3rd party packages */
@import "../../node_modules/normalize.css/normalize.css";

/* Functions */
@import "functions/breakpoints";

/* Base styles */
@import "base/font";
@import "base/variables";
@import "base/base";
@import "base/typography";


/*Custom styles*/
@import "components/nav";
@import "components/btn";
@import "components/link";
@import "components/carousel";


@import "layout/header";
@import "layout/hero";
@import "layout/dates";
@import "layout/registration";
@import "layout/registration-student";
@import "layout/tasks";
@import "layout/about";
@import "layout/contact";
@import "layout/footer";
@import "layout/galery";