.nav {
    @include respond(medium) {
        position: fixed;
        background: rgba(95, 105, 116, 0.2);
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        opacity: 0;
        visibility: hidden;
        transition: opacity 0.35s, visibility 0.35s;
        z-index: 80;

        &.is-open {
            opacity: 1;
            visibility: visible;
            height: 100%;

            &>.nav__list {
                height: 100%;
            }
        }
    }

    &__list {
        display: flex;
        flex-direction: row;
        list-style-type: none;
        gap: 2em;

        @include respond(medium) {
            position: absolute;
            padding: 0;
            top: 0;
            left: 0;
            height: 0;
            width: 100%;
            background-color: var(--bg-light);
            transition: height 0.35s;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            overflow: hidden;
        }
    }

    &__item {
        @extend .detail;
        position: relative;
        z-index: 5;

        &:before,
        &:after {
            content: "";
            position: absolute;
            display: block;
            bottom: 0;
            left: -5%;
            width: 110%;
            height: 10px;
            transform: skew(-20deg);
            background-color: var(--secondary);
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
            transition: clip-path 0.35s ease-in;
            z-index: -1;
        }

        &:after {
            background-color: var(--primary-hover);
        }

        &.active {
            &:before {
                clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
            }
        }

        &:hover {
            &:after {
                clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
            }
        }
    }

    &__button {
        position: relative;
        display: none;
        height: 4.4rem;
        width: 4.4rem;
        justify-content: center;
        align-items: center;
        background: none;
        border: 0;
        cursor: pointer;
        z-index: 100;

        @include respond(medium) {
            display: flex;
        }

        &--burger {
            position: relative;
            width: 4.4rem;
            height: 100%;
            cursor: pointer;
      

            i {
                background: var(--primary);
                border: none;
                height: 3px;
                position: absolute;
                top: 1rem;
                left: 0;
                transition: transform 0.35s ease;
                width: 3rem;
     


                &:nth-of-type(2) {
                    top: 2rem;
                }

                &:nth-of-type(3) {
                    top: 3rem;
                }
            }
        }

        &:hover {
            i {
                background: var(--secondary);
            }
        }

        &.is-activated {
            .nav__button--top {
                transform: translateY(1rem) translateX(0) rotate(45deg);
            }
            .nav__button--middle {
            opacity: 0;
            }
    
            .nav__button--bottom {
            transform: translateY(-1rem) translateX(0) rotate(-45deg);
            }
        }
    }

}