.image_gallery{
  margin: 10px 50px;
}
.image_gallery img{
  width: 400px;
  padding: 5px;
  //filter: grayscale();
  transition: 1s;
}

.image_gallery img:hover{
  //filter: grayscale(0);
  transform: scale(1.1);
}

.single_image_display {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0,0,0,0.9);
}
.image_content {
  margin: auto;
  display: block;
  max-width: 80%;
  max-height: 80%;
}
.close {
  position: absolute;
  top: 20px;
  right: 30px;
  font-size: 50px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}