.link {
    font-family: $family-primary;
    font-weight: $font-bold;
    font-size: var(--font-size-16);
    line-height: calc(var(--font-size-16) * 2);
    text-decoration: none;
    color: var(--text-primary);
    word-spacing: 1px;
    display: inline-block;

    &__highlight {
        position: relative;
        z-index: 1;

        &:before,
        &:after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 10px;
            transform: skew(-20deg);
            background-color: var(--secondary);
            z-index: -1;
        }

        &:after {
            background-color: var(--primary-hover);
            clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
            transition: clip-path 0.35s ease-in;
        }

        &:hover {
            &:after {
                clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
            }
        }

        &--white {
            &:before {
                background-color: var(--bg-white);
            }

            &:after {
                background-color: var(--secondary);
            }
        }
    }

    &__download {
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: calc(100% + 2rem);
            width: 26px;
            height: 21px;
            background-image: url("data:image/svg+xml,%3Csvg width='26' height='21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.293 15.707a1 1 0 0 0 1.414 0l6.364-6.364a1 1 0 0 0-1.414-1.414L13 13.586 7.343 7.929A1 1 0 0 0 5.93 9.343l6.364 6.364ZM12 0v15h2V0h-2Z' fill='%23061214'/%3E%3Cpath stroke='%23061214' stroke-width='2' d='M0 20h26'/%3E%3C/svg%3E");
        }
    }
}