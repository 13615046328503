.about {
    background-color: var(--bg-light);
    padding: var(--p-xl) 0;

    &__container {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--p-m);

        @include respond(small) {
            grid-template-columns: 1fr;
            gap: 1rem;
        }
    }

    &__seemore {
        display: flex;
        flex-direction:column;
        justify-content: flex-end;
        align-items: center;

        @include respond(small) {
            margin-top: 1rem;
        }

        h3 {
            align-self: flex-start;
            margin-bottom: var(--p-sm);

            @include respond(small) {
                margin-top: var(--p-m);
            }
        }

        a {
            display: block;
            width: 100%;
            height: auto;
            text-align: center;

            @include respond(small) {
                text-align: left;
            }

            &:hover {
                svg {
                    transform: scale(1.05);
                }
            }
        }

        svg {
            max-width: 80%;
            transition: transform 0.35s ease-in;
        }
    }
}