:root {
    //Colors 
    --primary: #c2c2c2;
    --primary-hover: #e20613;
    --secondary: #009FE3;
    --secondary-hover: #00B3FF;
    --tertiary: #ffcb08;
    --tertiary-hover: #ffe417;
    --quaternary: #e20613;
    --quaternary-hover: #f1414d;
    --text-primary: #1A1A1A;
    --text-secondary: #fff;
    --bg-light: #F2F2F2;
    --bg-dark: #1A1A1A;
    --bg-white: #ffffff;

    //Sizes
    --font-size: 62.5%;
    --font-size-48: 4.8rem;
    --font-size-42: 4.2rem;
    --font-size-40: 3.8rem;
    --font-size-20: 2rem;
    --font-size-18: 1.8rem;
    --font-size-12: 1.2rem;

    @include respond(small) {
        --font-size-48: 3.6rem;
        --font-size-42: 3.2rem;
        --font-size-40: 3rem;
        --font-size-20: 2rem;
        --font-size-18: 1.8rem;
        --font-size-12: 1.2rem;
    }

    //Padding
    --p-sm: 2rem;
    --p-m: 6rem;
    --p-lg: 8rem;
    --p-xl: 10rem;
    --p-xxl: 11rem;
}


//Fonts
$family-primary: 'JetBrains Mono',
    monospace;
$family-secondary: 'Inter',
    sans-serif;
$font-regular: 400;
$font-bold: 700;