*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: var(--font-size);
  scroll-behavior: smooth;
}

body {
  background-color: var(--bg-white);
  color: var(--text-primary);
  font-size: 1.8rem;
  box-sizing: border-box;
  position: relative;
  overflow-x: hidden;
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
  box-shadow: 0px 0px 0px 10px var(--secondary);
}

.container {
  max-width: 1320px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
}

body:not(.user-is-tabbing) a:focus,
body:not(.user-is-tabbing) button:focus,
body:not(.user-is-tabbing) input:focus,
body:not(.user-is-tabbing) select:focus,
body:not(.user-is-tabbing) textarea:focus {
  outline: none;
  box-shadow: none;
}
