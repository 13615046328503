.header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 9rem;
    background-color: var(--bg-white);
    transition: height 0.3s ease-in-out;
    z-index: 100;

    &__container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__logo {
        margin: 0;

        svg {
            width: 292px;
            height: auto;

            @include respond(small) {
                width: 20rem;
            }
        }
    
    }

    &.scrolled-down {
        height: 6.5rem; 
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;   
    }   
}