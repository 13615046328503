.btn {
    position: relative;
    font-family: $family-primary;
    font-weight: $font-bold;
    font-size: var(--font-size-20);
    line-height: calc(var(--font-size-20) * 1.05);
    text-decoration: none;
    color: var(--text-primary);
    text-transform: uppercase;
    word-spacing: 1px;
    padding: var(--p-sm);
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border: 0;

    @include respond(small) {
        font-size: 1.4rem;
    }

    &:before,
    &:after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        transform: skew(-20deg);
        transition: transform 0.2s ease-in-out;
    }

    &:after {
        z-index: -2;
    }

    &-primary {
        &:before {
            background-color: var(--quaternary);
        }

        &:after {
            background-color: var(--secondary);
        }

        &:hover {
            &:before {
                background-color: var(--quaternary-hover);
            }

            &:after {
                transform: skew(-20deg) translate(10px, 8px);
            }
        }
    }

    &-secondary {
        &:before {
            background-color: var(--secondary);
        }

        &:after {
            background-color: var(--quaternary);
        }

        &:hover {
            &:before {
                background-color: var(--secondary-hover);
            }

            &:after {
                transform: skew(-20deg) translate(10px, 8px);
            }
        }

        &>.small {
            font-family: $family-secondary;
            font-weight: $font-regular;
            font-size: var(--font-size-12);
            line-height: 1;
            color: var(--text-primary);
            text-transform: none;
            margin-top: 6px;
        }
    }

    &:disabled {
        cursor: not-allowed;

        @include respond(small) {
            width: 80%;
            margin: 0 auto;
            //margin-top: 4rem;
        }
        &:before {
            background-color: var(--bg-light);
        }
        &:after {
            content: none;
        }
    }
}