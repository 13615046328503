.contact {
    background-color: var(--bg-dark);
    padding: var(--p-xl) 0;

    h2, p, a {
        --primary: #009FE3;
        --primary-hover: #00B3FF;

        color: var(--text-secondary);
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: var(--p-m);

        @include respond(small) {
            grid-template-columns: 1fr;
            gap: 1rem;
        }
    }
}