/* jetbrains-mono-regular - latin-ext_latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../fonts/jetbrains-mono-v13-latin-ext_latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/jetbrains-mono-v13-latin-ext_latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* jetbrains-mono-700 - latin-ext_latin */
@font-face {
    font-family: 'JetBrains Mono';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('../fonts/jetbrains-mono-v13-latin-ext_latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/jetbrains-mono-v13-latin-ext_latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-regular - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('../fonts/inter-v12-latin-ext_latin-regular.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/inter-v12-latin-ext_latin-regular.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* inter-700 - latin */
@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    src: local(''),
        url('../fonts/inter-v12-latin-ext_latin-700.woff2') format('woff2'),
        /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('../fonts/inter-v12-latin-ext_latin-700.woff') format('woff');
    /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}