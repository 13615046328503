.hero {
	background-color: var(--bg-light);
	padding-top: calc(15vh + 9rem);
	position: relative;
	margin-bottom: var(--p-xxl);

	@include respond(medium) {
		padding-top: var(--p-m);
	}

	&:before {
		content: "";
		display: block;
		position: absolute;
		top: 100%;
		left: 0;
		width: 100%;
		height: 120px;
		background-color: var(--bg-light);
		clip-path: polygon(100% 0, 0 0, 50% 100%);

	}

	&__container {
		display: flex;

		@include respond(medium) {
			flex-direction: column-reverse;
			align-items: center;
		}
	}

	&__text {
		flex: 1;

		@include respond(medium) {
			margin: var(--p-m) 0;
		}
		
		span.edition {
		font-size: 1.5em;
		}

		span.highlight {
			position: relative;
			z-index: 1;
		
			&:before {
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: -3%;
				width: 100%;
				height: 2.5rem;
				transform: skew(-20deg);
				background-color: var(--secondary);
				z-index: -1;
			}
		}

		.display-1 {
			@include respond(medium) {
				text-align: center;
			}
		}

        .display-3 {
            padding-right: var(--p-lg);

			@include respond(medium) {
				padding-right: 0;
				text-align: center;
			}
        }
	}

	&__image {
		width: 535px;
		margin-right: -5rem;
		margin-top: -3rem;

		@include respond(medium) {
			width: 100%;
			height: auto;
			padding-top: var(--p-lg);
			margin: 0;
		}
		
		svg {
			width: 100%;
			height: auto;
			transform-box: fill-box; 
			
			@include respond(medium) {
				max-height: 50rem;

			}

			g {
				transform-origin: 50% 45%;
			}
		
		}
	}
}
