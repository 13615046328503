.carousel {

    &__item {
        width: 25%;
        height: 16.5rem;
        padding: var(--p-sm);
        display: flex;
        justify-content: center;

        @include respond(medium) {
            width: 33%;
        }

        @include respond(small) {
            width: 100%;
        }


        a {
            display: block;
            max-width: 30rem;
            height: 100%;

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
            transition: transform 0.35s ease-in-out;
        }
    }

    &__item_pictures {
        width: 45%;
        height: 22.5rem;
        padding: var(--p-sm);
        display: flex;
        justify-content: center;

        @include respond(medium) {
            width: 33%;
        }

        @include respond(small) {
            width: 100%;
        }
        a {
            display: block;
            max-width: 30rem;
            height: 100%;

            &:hover {
                img {
                    transform: scale(1.1);
                }
            }
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center center;
            transition: transform 0.35s ease-in-out;
        }
    }
}